
import styles from "./InfiniteSlider.module.scss";
import Slider from 'infinite-react-carousel';
import {useEffect, useRef, useState} from "react";

const InfiniteSlider = ({children, delay = 10000}) => {

    const prevArrowRef = useRef();
    const nextArrowRef = useRef();

    const [currentSlide, setCurrentSlide] = useState(1);
    const [windowSize, setWindowSize] = useState(0);

    useEffect(() => {
        if (!!window.innerWidth) {
            setWindowSize(window.innerWidth)
        }
    }, [window.innerWidth])

    const prevSlideHandler = () => {
        prevArrowRef.current.click();
        setCurrentSlide(prevState => prevState === 1 ? children.length : prevState - 1);
    }
    const nextSlideHandler = () => {
        nextArrowRef.current.click();
        setCurrentSlide(prevState => prevState === children.length ? 1 : prevState + 1);
    }

    const imageWidth = 800;
    const tabletWidth = 1024;

    const padding = windowSize > tabletWidth ? ((windowSize - imageWidth) / 2) : 60;

    return (
        <div className={styles.slider}>
            <div className={styles.transparent_left}/>
            <Slider
                autoplaySpeed={delay}
                autoplay
                arrows
                virtualList
                centerMode
                slidesToShow={1}
                centerPadding={padding}
                className={styles.carousel}
                nextArrow={<div ref={nextArrowRef}>next arrow</div>}
                prevArrow={<div ref={prevArrowRef}>prev arrow</div>}
                afterChange={(index) => setCurrentSlide(index + 1)}
                onResize={(e) => setWindowSize(e.currentTarget.innerWidth)}
            >
                {children.map(img => {
                    return <div key={img} className={styles.picture}>
                        <img src={img} alt={`Slide ${currentSlide}`} loading={"lazy"} width={800} height={400}/>
                    </div>
                })}
            </Slider>
            <div className={styles.transparent_right}/>
            {children.length > 1 &&
                <nav className={styles.navigation}>
                    <button
                        aria-label={"prev arrow"}
                        className={styles.prevArrow}
                        onClick={prevSlideHandler}
                    >
                        <svg width="16" height="8" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9 -4.689e-07L5 4L1 -1.19209e-07" stroke="#303133" strokeWidth="1.2" strokeLinecap="square"/>
                        </svg>
                    </button>
                    <div className={styles.count}>{`${currentSlide}/${children.length}`}</div>
                    <button
                        aria-label={"next arrow"}
                        className={styles.nextArrow}
                        onClick={nextSlideHandler}
                    >
                        <svg width="16" height="8" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9 -4.689e-07L5 4L1 -1.19209e-07" stroke="#303133" strokeWidth="1.2" strokeLinecap="square"/>
                        </svg>
                    </button>
                </nav>
            }
        </div>
    )
}

export default InfiniteSlider;